import FirstModal from "./components/FirstModal";
import LivePrice from "./components/LivePrice";
import Home from "./pages/Home";
import Second from "./pages/Second";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#2897f7",
            color: "#fff",
            boxShadow: "0px 0px 16px 2px rgba(40,151,247,0.75)",
            "-webkit-box-shadow": "0px 0px 16px 2px rgba(40,151,247,0.75)",
            "-moz-box-shadow": "0px 0px 16px 2px rgba(40,151,247,0.75)",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />

      <Home />
      <Second />
      {/* <LivePrice /> */}
    </div>
  );
}

export default App;
