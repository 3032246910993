import { BsCheck2Circle, BsBank, BsBank2, BsCalculator } from "react-icons/bs";
import { IoTelescopeOutline } from "react-icons/io5";
import { GrAdd } from "react-icons/gr";
import { AiOutlineCloudServer } from "react-icons/ai";
import { TbClock2 } from "react-icons/tb";
import { MdAdminPanelSettings } from "react-icons/md";
import {
  GiBank,
  GiCryptEntrance,
  GiWaterRecycling,
  GiTakeMyMoney,
  GiLightBulb,
} from "react-icons/gi";
import { ImStatsBars, ImLocation } from "react-icons/im";
import { RiAddFill } from "react-icons/ri";

const issues = [
  {
    name: "BUY",
    icon: <BsCheck2Circle />,
    details: "Click here to buy tokens",
  },
  {
    name: "SELL",
    icon: <BsCheck2Circle />,
    details: "Click here to sell tokens",
  },
  {
    name: "MINT",
    icon: <BsCheck2Circle />,
    details: "Click here to mint tokens",
  },
  { name: "NFT", icon: <BsCheck2Circle />, details: "Click here to buy NFTs" },
  {
    name: "BRIDGE",
    icon: <BsCheck2Circle />,
    details: "Click here to bridge tokens",
  },
  {
    name: "Migration",
    icon: <BsCheck2Circle />,
    details: "Click here for migration",
  },
  {
    name: "Stake/Unstake",
    icon: <BsCheck2Circle />,
    details: "Click here to stake or unstake tokens",
  },
  {
    name: "Migration Issues",
    icon: <BsCheck2Circle />,
    details: "Click here for migrationm related issues",
  },
  {
    name: "Assets Recovery",
    icon: <BsBank />,
    details: "Click here for assets recovery issues",
  },
  {
    name: "Rectification",
    icon: <BsBank />,
    details: "Click here to rectify issues",
  },
  {
    name: "High Gas Fees",
    icon: <IoTelescopeOutline />,
    details: "Click here for high gas fee issues",
  },
  {
    name: "Slippage Error",
    icon: <GiWaterRecycling />,
    details: "Click here for high gas fee issues",
  },
  {
    name: "Transaction Error",
    icon: <BsCalculator />,
    details: "Click here for transaction related issues",
  },
  {
    name: "Cross Chain Transfer",
    icon: <AiOutlineCloudServer />,
    details: "Click here for cross chain bridge issues",
  },
  {
    name: "Staking Issues",
    icon: <TbClock2 />,
    details: "Click here for high gas fee issues",
  },
  {
    name: "Swap/Exchange",
    icon: <BsBank2 />,
    details: "Click here for swap/exchange related issues",
  },
  {
    name: "Claim Reflection",
    icon: <BsBank2 />,
    details: "Click here for claim reflection",
  },
  {
    name: "Connect to Dapps",
    icon: <ImStatsBars />,
    details: "Click here for error while connecting to dapps",
  },
  {
    name: "Login Issues",
    icon: <MdAdminPanelSettings />,
    details: "Click here for wallet login issues",
  },
  {
    name: "Claim Airdrop",
    icon: <GiTakeMyMoney />,
    details: "Click here for airdrop related issues",
  },
  {
    name: "Missing/Incorrect Balance",
    icon: <GiLightBulb />,
    details: "Click here to recover lost/missing funds",
  },
  {
    name: "Whitelist Issues",
    icon: <GrAdd />,
    details: "Click here for whitelist related issues",
  },
  {
    name: "Transaction Delay",
    icon: <ImLocation />,
    details: "Click here for delayed transaction issues",
  },
  {
    name: "Locked Account",
    icon: <GiCryptEntrance />,
    details: "Click here for issues related to locked account",
  },
  {
    name: "Trading Wallet Issues",
    icon: <GiBank />,
    details: "Click here for issues trading related",
  },
  {
    name: "Unable to Buy coins/tokens",
    icon: <BsCalculator />,
    details:
      "To trade crypto, your account must be marked as a trusted payment source",
  },
  {
    name: "Other Isses not Listed",
    icon: RiAddFill,
    details: "Click here if your issues is not among the listed",
  },
];

export default issues;
