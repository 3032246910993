import React from "react";
import styled from "styled-components";
import { BsCheck2Circle, BsBank, BsBank2, BsCalculator } from "react-icons/bs";
import { IoTelescopeOutline } from "react-icons/io5";
import { GrMoney, GrAdd } from "react-icons/gr";
import { AiOutlineCloudServer } from "react-icons/ai";
import { TbClock2 } from "react-icons/tb";
import { MdAdminPanelSettings } from "react-icons/md";
import {
  GiBank,
  GiCryptEntrance,
  GiWaterRecycling,
  GiTakeMyMoney,
  GiLightBulb,
} from "react-icons/gi";
import { ImStatsBars, ImLocation } from "react-icons/im";
import { RiAddFill } from "react-icons/ri";

const Card = ({ name, icon, details }) => {
  return (
    <CardContainer>
      <div className="icon">{icon}</div>
      <h3 className="name">{name}</h3>
      <p>{details}</p>
    </CardContainer>
  );
};

export default Card;

const CardContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  width: 350px;
  padding: 20px 30px;
  border-radius: 14px;
  margin-bottom: 40px;
  cursor: pointer;

  .icon {
    width: 50px;
    height: 50px;
    background-color: #3f4143;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      font-size: 30px;
      color: #2897f7;
    }
  }
  .name {
    margin-top: 10px;
    font-size: 20px;
    color: aliceblue;
    margin-bottom: 15px;
  }
  p {
    color: aliceblue;
    font-size: 14px;
  }
`;
