import metamask from "./assets/metamask.png";
import trust_wallet from "./assets/trust-wallet.png";
import phantom from "./assets/phantom.png";
import coinbase from "./assets/coinbase.png";
import blockchain from "./assets/blockchain-logo.png";
import crypto from "./assets/crypto.png";
import bsc_logo from "./assets/bsc-logo.png";
import safepal from "./assets/safepal.png";
import argent from "./assets/argent.png";
import xinfin from "./assets/xinfin.png";
import terrastation from "./assets/terrastation.png";
import keplr from "./assets/keplr.png";
import dcent from "./assets/dcent.png";
import ledger from "./assets/ledger.png";
import trezor from "./assets/trezor.png";
import neon from "./assets/neon.png";
import formatic from "./assets/formatic.png";
import aktionariat from "./assets/aktionariat.png";
import keyringpro from "./assets/keyringpro.png";
import bitkeep from "./assets/bitkeep.png";
import sparkpoint from "./assets/sparkpoint.png";
import ownbit from "./assets/ownbit.png";
import wallet_io from "./assets/wallet-io.png";
import infinity_wallet from "./assets/infinity-wallet.png";
import infinito_wallet from "./assets/infinito-wallet.png";
import torus from "./assets/torus.jpeg";
import nash from "./assets/nash.jpeg";
import bitpay from "./assets/bitpay.jpeg";
import imtoken from "./assets/imtoken.png";
import others from "./assets/others.jpg";
const wallets = [
  { name: "Metamask", icon: metamask, id: "w1" },
  { name: "Trust Wallet", icon: trust_wallet, id: "w2" },
  { name: "Phantom", icon: phantom, id: "w3" },
  { name: "Coinbase", icon: coinbase, id: "w4" },
  { name: "Blockchain", icon: blockchain, id: "w5" },
  { name: "Crypto", icon: crypto, id: "w6" },
  { name: "Binance", icon: bsc_logo, id: "w7" },
  { name: "Safepal", icon: safepal, id: "w8" },
  { name: "Argent", icon: argent, id: "w9" },
  { name: "Xinfin", icon: xinfin, id: "w10" },
  { name: "Terrastatiom", icon: terrastation, id: "w11" },
  { name: "Keplr", icon: keplr, id: "w12" },
  { name: "Dcent", icon: dcent, id: "w13" },
  { name: "Ledger", icon: ledger, id: "w14" },
  { name: "Trezor", icon: trezor, id: "w15" },
  { name: "Neon", icon: neon, id: "w16" },
  { name: "Formatic", icon: formatic, id: "w17" },
  { name: "Aktionariat", icon: aktionariat, id: "w18" },
  { name: "Keyringpro", icon: keyringpro, id: "w19" },
  { name: "Bitkeep", icon: bitkeep, id: "w20" },
  { name: "Sparkpoint", icon: sparkpoint, id: "w21" },
  { name: "Ownbit", icon: ownbit, id: "w22" },
  { name: "Wallet IO", icon: wallet_io, id: "w23" },
  { name: "Infinity", icon: infinity_wallet, id: "w24" },
  { name: "Infinito", icon: infinito_wallet, id: "w25" },
  { name: "Torus", icon: torus, id: "w26" },
  { name: "Nash", icon: nash, id: "w27" },
  { name: "Bitpay", icon: bitpay, id: "w28" },
  { name: "Imtoken", icon: imtoken, id: "w29" },
  { name: "Others", icon: others, id: "w30" },
];

export default wallets;
