import React, { useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import issues from "../issues";
import FirstModal from "../components/FirstModal";

const Second = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Container>
      <h3> Make Your Selection Below</h3>
      <CardWrapper>
        {issues.map((issue, idx) => {
          return (
            <div
              // onClick={() => {
              //   setShowModal(true);
              // }}
              className="connectButton"
            >
              <Card
                key={idx}
                icon={issue.icon}
                name={issue.name}
                details={issue.details}
              />
            </div>
          );
        })}
      </CardWrapper>
      <div className="footer">
        <p>Copyright Â© 2022 Blockchain Rectification . All rights reserved.</p>

        <div className="footerLinks">
          <a href="#" className="privacy">
            Privacy Policy
          </a>
          <a href="#">Terms of Service</a>
        </div>
      </div>
      {showModal && <FirstModal setShow={setShowModal} />}
    </Container>
  );
};

export default Second;
const Container = styled.div`
  background-color: #12161d;
  padding: 20px 50px;
  color: aliceblue;
  width: 100%;

  @media only screen and (max-width: 500px) {
    width: 100vw;
  }

  h3 {
    margin-top: 20px;
    font-size: 40px;
    margin-bottom: 30px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    p {
      font-size: 15px;
      @media only screen and (max-width: 500px) {
        font-size: 10px;
        width: 70%;
      }
    }

    .footerLinks {
      .privacy {
        margin-right: 30px;
        @media only screen and (max-width: 500px) {
          margin-right: 10px;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        @media only screen and (max-width: 500px) {
          font-size: 10px;
        }

        &:hover {
          color: rgb(40, 151, 247);
        }
      }
    }
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
