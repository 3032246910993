import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import walletconnect from "../assets/wallet_connect.png";
import wallets from "../wallets";
import { GrFormClose } from "react-icons/gr";
import { Circles } from "react-loader-spinner";
import SecondModal from "./SecondModal";
import { toast } from "react-hot-toast";

const FirstModal = ({ setShow }) => {
  const [perPage, setPerPage] = useState(12);
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(12);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const [secondIcon, setSecondIcon] = useState();
  const [secondName, setSecondName] = useState();
  let pageNumbers = [];
  for (let i = 1; i <= Math.ceil(wallets.length / perPage); i++) {
    pageNumbers.push(i);
  }

  const paginatedWallet = wallets.slice(startPoint, endPoint);

  // const walletSelectHandler = () => {
  //   // setShow(false);
  //   alert
  // };

  const switchModalHandler = () => {
    setShowSpinner(true);
    setShowModal(false);
    setTimeout(() => {
      setShowSpinner(false);
      toast("Something went wrong! Please import wallet manually");
      setShowSecondModal(true);
    }, 3000);
  };
  return createPortal(
    <ModalWrapper>
      {showModal && (
        <Modal>
          <div className="head">
            <img src={walletconnect} alt="" />
            <p>WalletConnect</p>
          </div>
          <p className="pref">Choose your preferred wallet</p>

          <div className="walletWrapper">
            {paginatedWallet.map((wallet, idx) => {
              return (
                <div
                  key={wallet.id}
                  id={wallet.id}
                  className="wallet"
                  onClick={() => {
                    switchModalHandler();
                    setSecondIcon(wallet.icon);
                    setSecondName(wallet.name);
                  }}
                >
                  <img src={wallet.icon} alt="" />
                  <p>{wallet.name}</p>
                </div>
              );
            })}
          </div>
          <div className="numPagination">
            {pageNumbers.map((number, idx) => {
              return (
                <a
                  href="#"
                  onClick={() => {
                    if (number === 2) {
                      setStartPoint(13);
                      setEndPoint(25);
                    } else if (number === 3) {
                      setStartPoint(24);
                      setEndPoint(36);
                    } else if (number === 1) {
                      setStartPoint(0);
                      setEndPoint(12);
                    } else {
                      return;
                    }
                  }}
                >
                  {number}
                </a>
              );
            })}
          </div>
          <div
            className="closeBtn"
            onClick={() => {
              setShow(false);
            }}
          >
            <GrFormClose />
          </div>
        </Modal>
      )}
      {showSpinner && (
        <Circles
          height="80"
          width="80"
          color="#3a5692"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
      {showSecondModal && (
        <SecondModal setShow={setShow} icon={secondIcon} name={secondName} />
      )}
    </ModalWrapper>,
    document.getElementById("firstModal")
  );
};

export default FirstModal;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #fff;
  width: 500px;
  min-height: 280px;
  padding: 30px;
  border-radius: 15px;
  position: relative;

  @media only screen and (max-width: 500px) {
    width: 380px;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 60px;
    }
  }
  .pref {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    color: #797777;
  }

  .walletWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .wallet {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        width: 45px;
        margin-bottom: 5px;
      }
      p {
        font-size: 8px;
        text-align: center;
      }
    }
  }
  .numPagination {
    display: flex;
    gap: 25px;
    justify-content: center;
    margin-top: 15px;

    a {
      text-decoration: none;
      color: #0b0b27;
    }
  }
  .closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      font-size: 25px;
    }
  }
`;
