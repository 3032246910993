import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import styled from "styled-components";
import bg from "../assets/bgmain.png";
import logo from "../assets/logo.png";
import LivePrice from "../components/LivePrice";
import ScrollPrices from "../components/ScrollPrices";

const Home = () => {
  return (
    <Container>
      <Hero>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="content">
          <h3>Blockchain</h3>
          <h3>Rectification</h3>
          <p>
            Every digital artwork on Upside is authentic and truly unique.
            Blockchain technology makes this new aproch to digital ownership
            possible. Open and decentralized protocol for syncing various
            Wallets issues on Secure Server.
          </p>
        </div>
        <div className="liveCharts">
          <h3>Live Market Prices</h3>
          <LivePrice />
        </div>
        <div className="actionBtns">
          <button className="connectButton">Connect Wallet</button>
          <select
            name=""
            id=""
            onChange={() => {
              toast("Please click on the connect button to connect!");
            }}
          >
            {[
              "Select Chain",
              "Ethereum",
              "Binance S",
              "Polygon",
              "Avalanche",
              "Fantom",
              "Arbitrum",
              "Optimism",
              "Cronos",
            ].map((opt, idx) => {
              return (
                <option
                  value={opt}
                  disabled={idx === 0}
                  selected={idx === 0}
                  key={idx}
                >
                  {opt}
                </option>
              );
            })}
          </select>
        </div>
      </Hero>
      <ScrollPrices />
    </Container>
  );
};

export default Home;

const Container = styled.div`
  width: 100%;
`;
const Hero = styled.div`
  min-height: 100vh;
  position: relative;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    position: unset;
  }

  .liveCharts {
    position: absolute;
    right: 30px;
    width: 400px;
    top: 30px;
    max-height: 650px;
    overflow-y: scroll;

    h3 {
      text-align: center;
      color: #fff;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 500px) {
      display: none;
      position: relative;
      /* margin-top: 500px; */
    }
  }

  .logo {
    position: absolute;
    left: -70px;
    top: -80px;
    img {
      width: 280px;
    }
    @media only screen and (max-width: 500px) {
      position: relative;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background: url(${bg}) no-repeat center center;
    opacity: 0.5;
    z-index: 0;
    animation-name: bounce;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @media only screen and (max-width: 500px) {
      /* display: none; */
      position: absolute;
    }
    /* z-index: -1; */

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .content {
    position: absolute;
    top: 180px;
    margin-left: 50px;
    color: #fff;

    h3 {
      font-size: 60px;
      margin-bottom: 10px;
      @media only screen and (max-width: 500px) {
        font-size: 50px;
      }
    }
    p {
      width: 50%;
      line-height: 1.8;
      font-size: 17px;
      @media only screen and (max-width: 500px) {
        width: 90%;
        font-size: 14px;
      }
    }
    @media only screen and (max-width: 500px) {
      position: absolute;
      top: 100px;
      margin-bottom: 40px;
    }
  }
  .actionBtns {
    position: absolute;
    bottom: 50px;
    left: 50px;

    @media only screen and (max-width: 500px) {
      position: absolute;
      bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
      /* align-items: center; */
      gap: 10px;
      width: 100%;
    }

    button {
      margin-right: 40px;
      width: 180px;
      background-color: #2897f7;
      height: 47px;
      border: none;
      outline: none;
      color: aliceblue;
      border-radius: 10px;
      cursor: pointer;
      font-size: 16px;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: transparent;
        border: 1px solid #2897f7;
      }
    }
    select {
      width: 180px;
      height: 47px;
      text-align: center;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid #2897f7;
      color: aliceblue;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
`;
