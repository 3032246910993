import React from "react";
import styled from "styled-components";

const LivePrice = () => {
  return (
    <div
      style={{
        height: "964px",
        backgroundColor: "#1D2330",
        overflow: "hidden",
        boxSizing: "border-box",
        border: "1px solid #282E3B",
        borderRadius: "4px",
        textAlign: "right",
        lineHeight: "14px",
        fontSize: "12px",
        "font-feature-settings": "normal",
        "text-size-adjust": "100%",
        boxshadow: "inset 0 -20px 0 0 #262B38",
        padding: "0px",
        margin: "0px",
        width: "100%",
      }}
      className="bigPrice"
    >
      <div
        style={{
          height: "944px",
          padding: "0px",
          margin: "0px",
          width: "100%",
        }}
      >
        <iframe
          src="https://widget.coinlib.io/widget?type=full_v2&theme=dark&cnt=15&pref_coin_id=637&graph=yes"
          width="100%"
          height="940px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style={{ border: "0", margin: "0", padding: "0" }}
        ></iframe>
      </div>
      <div
        style={{
          color: "#626B7F",
          lineHeight: "14px",
          fontWeight: "400",
          fontSize: "11px",
          boxSizing: "border-box",
          padding: "2px 6px",
          width: "100%",
          fontFamily: "Verdana, Tahoma, Arial, sans-serif",
        }}
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style={{
            fontWeight: "500",
            color: "#626B7F",
            textDecoration: "none",
            fontSize: "11px",
          }}
        >
          Cryptocurrency Prices
        </a>
        &nbsp;by Defi Solutions
      </div>
    </div>
  );
};

export default LivePrice;

const Wrapper = styled.section``;
